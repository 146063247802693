import { childElements, forEachElement, isAppEnvironment } from "../util/Utils";
import { sheetContent } from "./Selectors";
import { registerClickTracker, sanFoldout } from "./FilterSheetTracking";
import { eventQBus } from "../types/EventQBus";
import FilterNavigationSheet, { LOCAL_NAVIGATION } from "./FilterNavigationSheet";
import FilterDetailSheet from "./FilterDetailSheet";
import type { SheetCloseTypes } from "@otto-ec/global-pattern/pattern/700_Layer_und_Popup/400_pl_sheet/sheetEvents";
import FilterSheet, { SIDEBAR_CHANGE_TRACKING_LABELS } from "./FilterSheet";
import { setActiveConfiguration } from "../components";

function closeAllTooltips(sheetContent: HTMLElement) {
  /*                                                                                          */
  forEachElement(
    ".pl_tooltip--bottom .pl_icon",
    (tooltip) => tooltip.dispatchEvent(new Event("click", { bubbles: true })),
    sheetContent,
  );
}

function closeAllTooltipsListener(event: Event) {
  const target = event.target as HTMLElement;
  const currentTarget = event.currentTarget as HTMLElement;
  if (!target.closest(".pl_tooltip--bottom")) {
    closeAllTooltips(currentTarget.parentElement || currentTarget);
  }
}

function fixSheet(element?: HTMLElement) {
  element?.addEventListener("click", closeAllTooltipsListener, { capture: true, passive: true });
  element?.classList.add("heureka_filterSheet");
}

export function createSheetTemplate(
  content: HTMLElement | HTMLElement[] | null,
  menuSource: HTMLElement | null,
  showBackButton?: boolean,
  title?: string,
) {
  if (content) {
    const initialMobileHeight = isAppEnvironment() ? "90vh" : "80vh";
    const sheet = new window.o_global.pali.sheet({
      title,
      /*                                             */
      content: content as unknown as string,
      menuContent: (childElements(menuSource) || []) as unknown as string,
      showBackButton,
      fullBleedContent: true,
      initialMobileHeight,
      openCallback: openCallback,
      closeCallback: closeCallback,
      createTrackingContext: false,
    });

    fixSheet(sheet.getTitle());
    fixSheet(sheet.getContent());
    fixSheet(sheet.getMenuBar());
    return sheet;
  }
}

function openCallback() {
  const elem = sheetContent();
  const { filterId } = elem?.dataset || {};

  registerClickTracker(".pl_sheet", {
    capture: true,
    passive: true,
  });

  eventQBus.emit("ftfind.filterSheet.opened", {
    filterId,
  });
}

/**
 *
 */
function closeCallback(params: { closeType: SheetCloseTypes }) {
  /*                                                                                            */
  const content = sheetContent();
  const { filterId, tsLabels = "{}" } = content?.dataset || {};

  if (filterId) {
    switch (filterId) {
      case LOCAL_NAVIGATION:
        FilterNavigationSheet.declare().deactivate();
        break;
      default:
        FilterDetailSheet.declare(filterId).submitFilter(params.closeType).deactivate(SIDEBAR_CHANGE_TRACKING_LABELS);
    }
  } else {
    FilterSheet.declare().deactivate(SIDEBAR_CHANGE_TRACKING_LABELS);
  }
  setActiveConfiguration("filterAccordion");
  return eventQBus.emit("ftfind.filterSheet.closed", {
    filterId,
    tracking: {
      san_Foldout: sanFoldout(params.closeType),
      ...JSON.parse(tsLabels),
    },
  });
}
