import { eventQBus } from "../types/EventQBus";
import { ConfigurableFacet, MaterialFacet } from "../components";
import { isMobile } from "../util/Breakpoint";
import { mount } from "svelte";
import type { FacetType, FacetValueLabelType, FacetValueType } from "../components/type";
import { mapElements } from "../util/Utils";
import { FilterSection } from "./FilterSection";

function initializeConfigurableFacet() {
  mapElements(".ts_heureka_svelteFacet", (elem) => {
    const parentElement = elem.parentElement as HTMLElement;
    const { filterId, facetVisibility, serialized } = elem.dataset;
    if (filterId === "bewertung" || filterId === "material") {
      const facetValueLabelType: Record<string, FacetValueLabelType> = {
        bewertung: "rating",
        /*             */
      };
      const facetValueType: Record<string, FacetValueType> = {
        bewertung: "radio",
        /*                 */
      };
      const facetType: Record<string, FacetType> = {
        bewertung: "simple",
        /*                   */
      };

      mount(ConfigurableFacet, {
        target: parentElement,
        props: {
          data: JSON.parse(serialized),
          parentId: `find_filter_${filterId}`,
          facetValueLabelConfig: {
            type: facetValueLabelType[filterId],
          },
          facetValueConfig: {
            type: facetValueType[filterId],
          },
          facetConfig: {
            type: facetType[filterId],
            visibility: facetVisibility,
            crispy: FilterSection.isCrispyFiltersListActive(),
          },
        },
      });
    } else {
      mount(MaterialFacet, {
        target: parentElement,
        props: {
          ...JSON.parse(elem.dataset.serialized),
          mode: isMobile() ? "detailSheet" : "filterAccordion",
        },
      });
    }
    elem.remove();
    return parentElement;
  });
}

export default function registerSvelteComponentInitialization() {
  eventQBus.on("heureka.filters.loaded", initializeConfigurableFacet);
}
