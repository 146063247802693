<script lang="ts">
  import type { FacetValue } from "../facet-value";
  import type { Facet, FacetOptions } from "./type.ts";
  import { List } from "../facet-value-group";

  import { featureTrackingAttributes } from "../facet-value/Tracking.ts";
  import type { ClassValue } from "svelte/elements";

  interface Props extends Facet, FacetOptions {
    class?: ClassValue;
  }

  let { name, values, featureTracking, visibility, crispy, contentSnippet = defaultList, ...rest }: Props = $props();

  const id = "facet_customerreview";

  /*                                                                                                    */
  const hidden =
    !!visibility && (crispy ? visibility === "hidden" : visibility === "hidden" || visibility === "irrelevant");
  if (featureTracking) {
    if (!crispy && hidden) {
      delete featureTracking.status;
    }
  }

  const addMissingFacetData = ({ featureTracking, ...rest }: FacetValue) => ({
    name,
    ...rest,
    ...(featureTracking && { featureTracking: { parentId: id, ...featureTracking } }),
  });

  const namedValues = $derived(values.map(addMissingFacetData));
</script>

{#snippet defaultList(values: FacetValue[])}
  <List {values}></List>
{/snippet}

<fieldset
  {id}
  {name}
  class={["hc_facet", rest.class]}
  data-facet-visibility={visibility}
  {...featureTrackingAttributes(featureTracking)}
  {hidden}
>
  {@render contentSnippet(namedValues)}
</fieldset>

<style lang="scss">
  fieldset {
    min-width: 0;
  }
</style>
