import type { FeatureTracking } from "./FeatureTracking";
import type { FeatureTrackingReference } from "./FeatureTrackingReference";

type FeatureTrackingAttributes = {
  "data-ts-feature-name": string;
  "data-ts-feature-status"?: string;
  "data-ts-feature-parent-id"?: string;
  "data-ts-feature-position"?: string;
  "data-ts-feature-labels"?: string;
  "data-ts-feature-filter-method"?: string;
};

export function featureTrackingAttributes(featureTracking?: FeatureTracking): FeatureTrackingAttributes | undefined {
  /*                                          */
  if (featureTracking) {
    const { name, status, position, parentId, labels, method } = featureTracking;
    const featureLabels = labels ? JSON.stringify(labels) : undefined;
    /*                                                                           */
    return {
      "data-ts-feature-name": name,
      ...(status && { "data-ts-feature-status": status }),
      ...(parentId && { "data-ts-feature-parent-id": parentId }),
      ...(position && { "data-ts-feature-position": position }),
      ...(featureLabels && { "data-ts-feature-labels": featureLabels }),
      ...(method && { "data-ts-feature-filter-method": method }),
    };
  }
}

export function addFeatureTrackingReference(
  node: HTMLElement,
  featureTrackingReference: Partial<FeatureTrackingReference> | undefined,
) {
  /*                                          */
  if (featureTrackingReference?.ref) {
    const featureLabels = featureTrackingReference.labels ? JSON.stringify(featureTrackingReference.labels) : undefined;

    const attributes = {
      tsFeatureLabels: featureLabels,
      tsFeatureRef: featureTrackingReference.ref,
    };

    Object.entries(attributes).forEach(([key, value]) => {
      if (value !== undefined) {
        node.dataset[key] = value;
      }
    });
  }

  return {
    update() {},
    destroy() {},
  };
}
