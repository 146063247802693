import "./sass/private.scss";
import { eventLoader } from "@otto-ec/global-resources/event-loader";
import { registerFilterSheetHandlers } from "./filtersheet/FilterSheetHandlers";
import { registerFilterSheetTracking } from "./filtersheet/FilterSheetTracking";
import { registerFilterSheetHistory } from "./filtersheet/FilterSheetHistory";
import { registerQuickFilterButtonHandlers } from "./quickfilter/QuickFilterButton";
import registerSearchableFacetHandlers from "./filter/SearchableFacet";
import registerFilterFormActionsHandlers from "./filter/FilterFormActions";
import { SubmitOnChangeListener } from "./filter/SubmitOnChange";
import { initToggles } from "./toggle/Toggle";
import registerExpanderHandlers from "./expander/Expander";

import "./filter/FilterSection";
import { OverflowBox } from "./overflowBox/OverflowBox";
import { initExperiments } from "./experiment/Experiments";
import { registerFilterSheetEventListeners } from "./filtersheet/FilterSheetEventListeners";
import { registerFilterReloading } from "./multifiltering/init";
import { registerSheetOverflowBoxTrackingHandlers } from "./overflowBox/SheetInlineFilterOverflowBoxTracking";
import { registerSheetExpanderTrackingHandlers } from "./expander/SheetInlineFilterExpanderTracking";
import { registerFilterFormSelectAllButtons } from "./filter/form/SelectAllButton";
import { registerPopularFacetValuesListeners } from "./filter/PopularFacetValues";
import { registerSorting } from "./sorting/init";
import { registerRefinementBar } from "./refinementBar/init";
import { registerFilterStuff } from "./filter/init";
import { FilterSorting } from "./filter/FilterSorting";
import { FilterSection } from "./filter/FilterSection";
import { Slider } from "./filter/slider/Slider";
import registerFilterTracking from "./filter/FilterTracking";
import { FilterSortingOld } from "./filter/FilterSortingOld";
import { FilterlistExpander } from "./filterlistExpander/FilterlistExpander";
import { ChangeCounter } from "./filter/ChangeCounter";
import registerSvelteComponentInitialization from "./filter/SvelteComponentInitialization";

eventLoader.onReady(1, () => {
  initToggles();
  initExperiments();
});

eventLoader.onReady(2, () => {
  registerExpanderHandlers();
  OverflowBox.register();
  registerSvelteComponentInitialization();
});

eventLoader.onReady(3, () => {
  Slider.register();
  registerFilterSheetHandlers();
  FilterSection.register();
  registerFilterSheetEventListeners();
  registerFilterSheetHistory();
  registerFilterSheetTracking();
  registerQuickFilterButtonHandlers();
  registerSheetOverflowBoxTrackingHandlers();
  registerSheetExpanderTrackingHandlers();
  registerSearchableFacetHandlers();
  registerFilterFormSelectAllButtons();
  registerFilterFormActionsHandlers();
  if (FilterSection.isCrispyFiltersListActive()) {
    FilterSorting.register();
    FilterlistExpander.register();
  } else {
    FilterSortingOld.register();
  }
  registerFilterStuff();
  registerSorting();
  SubmitOnChangeListener.register();
  ChangeCounter.register();
  registerPopularFacetValuesListeners();
  registerRefinementBar();
  registerFilterTracking();
  registerFilterReloading();
});
