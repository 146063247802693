<script module lang="ts">
  import type {
    FilterMode,
    FacetValueLabelType,
    FacetValueLabelConfiguration,
    FacetValueType,
    FacetValueConfiguration,
    FacetValueGroupType,
    FacetValueGroupConfiguration,
    FacetType,
    FacetConfiguration,
  } from "./type";

  const defaultFacetValueLabelType: FacetValueLabelType = "text";
  const defaultFacetValueLabelOptions: Partial<Record<FilterMode, FacetValueLabelOptions>> = {
    filterAccordion: { size: 125, titleSize: 100 },
    detailSheet: { fitContent: false, size: 125 },
  };
  const defaultFacetValueType: FacetValueType = "checkbox";
  const defaultFacetValueOptions: Partial<Record<FilterMode, FacetValueOptions>> = {};
  const defaultFacetValueGroupType: FacetValueGroupType = "list";
  const defaultFacetValueGroupOptions: Partial<Record<FilterMode, FacetValueGroupOptions>> = {};
  const defaultFacetType: FacetType = "scrollable";
  const defaultFacetOptions: Partial<Record<FilterMode, FacetOptions>> = {
    detailSheet: { scrollable: false },
  };
</script>

<script lang="ts">
  import type { FacetValueLabelOptions } from "@otto-ec/heureka-components/facet-value-label";
  import type { FacetValue, FacetValueOptions } from "@otto-ec/heureka-components/facet-value";
  import type { FacetValueGroupOptions } from "@otto-ec/heureka-components/facet-value-group";
  import type { Facet, FacetOptions } from "@otto-ec/heureka-components/facet";
  import { Text, Rating } from "@otto-ec/heureka-components/facet-value-label";
  import { Checkbox, Radio } from "@otto-ec/heureka-components/facet-value";
  import { List, OverflowBox } from "@otto-ec/heureka-components/facet-value-group";
  import { Scrollable, Simple } from "@otto-ec/heureka-components/facet";
  import type { Snippet } from "svelte";
  import { activeConfiguration } from "./stores.svelte";

  interface Props {
    data: Facet;
    parentId: string;
    facetValueLabelConfig?: FacetValueLabelConfiguration;
    facetValueConfig?: FacetValueConfiguration;
    facetValueGroupConfig?: FacetValueGroupConfiguration;
    facetConfig?: FacetConfiguration;
  }

  const {
    data,
    parentId = "find_filter_material",
    facetValueLabelConfig = {},
    facetValueConfig = {},
    facetValueGroupConfig = {},
    facetConfig = {},
  }: Props = $props();

  const mode = $derived(activeConfiguration.mode);

  const { type: facetValueLabelType = defaultFacetValueLabelType, ...userFacetValueLabelOptions } =
    facetValueLabelConfig;
  const { type: facetValueType = defaultFacetValueType, ...userFacetValueOptions } = facetValueConfig;
  const { type: facetValueGroupType = defaultFacetValueGroupType, ...userFacetValueGroupOptions } =
    facetValueGroupConfig;
  const { type: facetType = defaultFacetType, ...userFacetOptions } = facetConfig;

  /*                                 */
  const facetValueLabelOptions: FacetValueLabelOptions = $derived({
    ...defaultFacetValueLabelOptions[mode],
    ...userFacetValueLabelOptions,
  });

  const facetValueLabelMap: Record<FacetValueLabelType, Snippet<[FacetValue]>> = {
    text,
    rating,
  };

  /*                           */
  const facetValueOptions: FacetValueOptions = $derived({
    ...defaultFacetValueOptions[mode],
    forceAriaLabel: facetValueLabelType === "rating",
    ...userFacetValueOptions,
  });

  const facetValueMap: Record<FacetValueType, Snippet<[FacetValue]>> = {
    checkbox,
    radio,
  };

  /*                                 */
  const facetValueGroupOptions: FacetValueGroupOptions = $derived({
    ...defaultFacetValueGroupOptions[mode],
    item: facetValueMap[facetValueType],
    ...userFacetValueGroupOptions,
  });

  const facetValueGroupMap: Record<FacetValueGroupType, Snippet<[FacetValue[]]>> = {
    list,
    overflowBox,
  };

  /*                     */
  const facetOptions: FacetOptions = $derived({
    ...defaultFacetOptions[mode],
    contentSnippet: facetValueGroupMap[facetValueGroupType],
    ...userFacetOptions,
  });

  const facetMap: Record<FacetType, Snippet<[Facet]>> = {
    scrollable,
    simple,
  };

  /*       */
  const label: Snippet<[FacetValue]> = facetValueLabelMap[facetValueLabelType];
  const facet: Snippet<[Facet]> = facetMap[facetType];

  /*       */
  const featureTracking = { ...data.featureTracking, parentId };
</script>

{#snippet text(facetValue: FacetValue)}
  <Text {...facetValue} {...facetValueLabelOptions} />
{/snippet}

{#snippet rating(facetValue: FacetValue)}
  <Rating {...facetValue} {...facetValueLabelOptions} />
{/snippet}

{#snippet checkbox(facetValue: FacetValue)}
  <Checkbox {...facetValue} {...facetValueOptions}>
    {@render label(facetValue)}
  </Checkbox>
{/snippet}

{#snippet radio(facetValue: FacetValue)}
  <Radio {...facetValue} {...facetValueOptions}>
    {@render label(facetValue)}
  </Radio>
{/snippet}

{#snippet list(values: FacetValue[])}
  <List {values} {...facetValueGroupOptions} />
{/snippet}

{#snippet overflowBox(values: FacetValue[])}
  <OverflowBox {values} {...facetValueGroupOptions} />
{/snippet}

{#snippet scrollable(facet: Facet)}
  <Scrollable class="find_facet" {...facet} {...facetOptions} />
{/snippet}

{#snippet simple(facet: Facet)}
  <Simple class="find_facet" {...facet} {...facetOptions} />
{/snippet}

{@render facet({ ...data, featureTracking })}
