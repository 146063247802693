<script lang="ts">
  import type { FacetValue } from "../facet-value";
  import type { Facet, FacetOptions } from "./type.ts";
  import { List } from "../facet-value-group";

  import { featureTrackingAttributes } from "../facet-value/Tracking.ts";
  import type { ClassValue } from "svelte/elements";

  interface Props extends Facet, FacetOptions {
    class?: ClassValue;
    scrollable?: boolean;
  }

  let {
    name,
    values,
    featureTracking,
    visibility,
    crispy,
    contentSnippet = defaultList,
    scrollable = true,
    ...rest
  }: Props = $props();

  const id = "facet_materialdescription";

  /*                                                                                                    */
  const hidden =
    !!visibility && (crispy ? visibility === "hidden" : visibility === "hidden" || visibility === "irrelevant");
  if (featureTracking) {
    if (!crispy && hidden) {
      delete featureTracking.status;
    }
  }

  const isSelected = (value: FacetValue) => value.checked;
  const isNotSelected = (value: FacetValue) => !isSelected(value);
  const addMissingFacetData = ({ featureTracking, ...rest }: FacetValue) => ({
    name,
    ...rest,
    ...(featureTracking && { featureTracking: { parentId: id, ...featureTracking } }),
  });

  const withTrackingPosition = (value: FacetValue, position: number) => {
    if (value.featureTracking) {
      value.featureTracking.position = `${position}`;
    }
    return value;
  };

  const selectedValues = $derived(
    values
      .filter(isSelected)
      .map(addMissingFacetData)
      .map((value, idx) => {
        return withTrackingPosition(value, idx + 1);
      }),
  );
  const unselectedValues = $derived(
    values
      .filter(isNotSelected)
      .map(addMissingFacetData)
      .map((value, idx) => {
        return withTrackingPosition(value, idx + 1 + selectedValues.length);
      }),
  );
</script>

{#snippet defaultList(values: FacetValue[])}
  <List {values}></List>
{/snippet}

<fieldset
  {id}
  {name}
  class={["hc_facet", rest.class]}
  data-facet-visibility={visibility}
  {...featureTrackingAttributes(featureTracking)}
  {hidden}
>
  {@render contentSnippet(selectedValues)}
  <div class={["hc_facet__scrollBox", { "hc_facet__scrollBox--scrollable": scrollable }]}>
    {@render contentSnippet(unselectedValues)}
  </div>
</fieldset>

<style lang="scss">
  fieldset {
    min-width: 0;
  }

  .hc_facet__scrollBox {
    &--scrollable {
      max-height: var(--scroll-area-height, 365px);
      overflow-y: auto;
      margin-left: calc(-2 * var(--oc-semantic-focus-outline-offset));
      padding-left: calc(2 * var(--oc-semantic-focus-outline-offset));
    }
  }
</style>
